import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { LinearProgress, linearProgressClasses } from '@mui/material';
import '../App.css';

// const styles = theme => ({
//     root: {
//       width: '100%',
//     },
//     barColorPrimary: {
//         backgroundColor: blue[500]
//     },
//     colorPrimary: {
//         backgroundColor: grey[300]
//     }
// });
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 7,
    borderRadius: 15,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

class ProgressQuest extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { value } = this.props;
        return (
            <BorderLinearProgress variant="determinate" value={value} />
            // <LinearProgress variant="determinate"
            //     classes={{
            //         colorPrimary: classes.colorPrimary,
            //         barColorPrimary: classes.barColorPrimary
            //     }}
            //     style={{ height: 7, borderRadius: 15 }}
            //     value={value}
            // />
        )
    }
}

// ProgressQuest.propTypes = {
//     classes: PropTypes.object.isRequired
// }

export default ProgressQuest;
