import React, {Component} from 'react';
import { Image, Button } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import '../../App.css';

import hand_down from '../../assets/hand-down.jpeg';

import const_values from '../../utils/ConstValues';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default class Evaluation extends Component{

    constructor(){
        super();
        this.state={
            withCode: true,
            codeEvaluation: '',
            password: '',
            evaluation: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.login = this.login.bind(this);
    }

    componentDidMount(){
        let code = this.props.params.evaluation;
        this.validateEvaluation(code);
    }

    validateEvaluation(code){
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/evaluation_by_codigo/'+code)
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                if(data.text.nombre_enlace !== ''){
                    if(data.text.activar_seguridad === '1'){
                        this.setState({
                            withCode: true,
                            codeEvaluation: data.text.codigo_seguridad,
                            evaluation: data.text
                        })
                    }else{
                        this.setState({
                            withCode: false,
                            evaluation: data.text
                        })
                    }
                }else{
                    toast.warning('El link no es valido');
                    this.props.navigate('/');
                }
            }else{
                toast.warning('El link no es valido');
                this.props.navigate('/');
            }
        })
        .catch(console.error)
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    login(){
        let validation = true;
        if(this.state.withCode){
            if(this.state.password === ''){
                validation = false;
            }
            if(this.state.codeEvaluation === this.state.password){
                validation = true;
            }else{
                validation = false;
            }
        }
        if(validation){
            firebase.auth().signInAnonymously()
            .then(() => {
                toast.info('Bienvenido');
                // this.props.navigate('')
            })
            .catch((error) => {
                console.error(error);
            })
        }else{
            toast.error('Debe ingrese un codigo valido para continuar');
        }
    }
    
    render(){
        // const { match, location, history } = this.props;
        return(
            <div className="login-div">
                <div className="header-login">
                    {this.state.evaluation != '' &&
                    <h3>
                        Gracias por tu interés 
                        en el proceso de {this.state.evaluation.cargo} para la 
                        empresa {this.state.evaluation.razon_social}.
                    </h3>
                    }
                    <div style={{marginTop: 20, marginBottom:20}}>
                        <Image src={hand_down} style={{width: 100}}/>
                    </div>
                    {this.state.withCode &&
                        <div>Por favor, ingresa el código de seguridad.</div>
                    }
                </div>
                <div className="div-element">
                    {this.state.withCode &&
                    <TextField 
                        id="password" 
                        label="Contraseña" 
                        variant="outlined"
                        type="password" 
                        name="password"
                        value={this.state.password} 
                        onChange={this.handleInputChange}
                        className="input"
                    />
                    }
                </div>
                <div className="footer-login">
                    <Button className="button" variant='primary' onClick={this.login.bind()}>Continuar</Button>
                </div>
            </div>
        )
    }
}
