import React, {Component} from 'react';
import { Button } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import '../../App.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default class Migration extends Component{

    constructor(){
        super();
        this.state={
            email: '',
            password: '',
            message: []
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.login = this.login.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    login(){
        let validation = this.validateFields();
        if(validation){
            firebase.auth().signInWithEmailAndPassword(this.state.email,this.state.password)
            .then(() => {
                toast.info('Bienvenido');
            })
            .catch((error) => {
                const errorCode = error.code;
                if(errorCode === 'auth/wrong-password'){
                    toast.error('Contraseña incorrecta');
                }else{
                    toast.error('El usuario no se encuentra registrado');
                }
            })
        }
    }

    validateFields(){
        let validation = true;

        if(this.state.email === ''){
            toast.error('El campo email es obligatorio');
            validation = false;
        }else{
            let validEmail = /@/.test(this.state.email);
            if(!validEmail){
                toast.error('Email incorrecta');
                validation = false;
            }
        }
        if(this.state.password === ''){
            toast.error('El campo contraseña es obligatorio');
            validation = false;
        }else{
            if(this.state.password.length < 6){
                toast.error('La contraseña debe tener mas de 6 caracteres');
                validation = false;
            }
        }
        return validation;
    }

    render(){
        // const { match, location, history } = this.props;
        return(
            <div className="login-div">
                <div className="header-login">
                    <h3>Iniciar sesión</h3>
                    <br/>
                    <h6>Inicia sesión con las credenciales enviadas a tu correo electrónico</h6>
                </div>
                <div className="div-element">
                    <TextField 
                        id="email" 
                        label="Email" 
                        variant="outlined"
                        name="email"
                        value={this.state.email} 
                        onChange={this.handleInputChange} 
                        className="input"
                    />
                </div>
                <div className="div-element">
                    <TextField 
                        id="password" 
                        label="Contraseña" 
                        variant="outlined"
                        type="password" 
                        name="password"
                        value={this.state.password} 
                        onChange={this.handleInputChange}
                        className="input"
                    />
                </div>
                <div className="footer-login">
                    <Button className="button" variant='primary' onClick={this.login.bind()}>Ingresar</Button>
                </div>
            </div>
        )
    }
}
