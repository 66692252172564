import React, {Component} from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../App.css';
import logo from '../assets/logopequeno1.png'
import close_inactive from '../assets/cerrar-session-inactive.png';
import close_active from '../assets/cerrar-session-active.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default class Header extends Component{

    constructor(props){
        super(props);
        this.state ={
            closeImage: close_inactive,
            btnClose: (<div/>)
        }
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
    }

    // componentDidMount(){
    //     console.log(window.location.pathname);
    //     let regExp = new RegExp('/evaluation');
    //     if(regExp.test(window.location.pathname)){
    //         console.log(window.location.pathname.split('/'));
    //     }
    // }

    onMouseEnter(){
        this.setState({closeImage: close_active});
    }
    
    onMouseOut(){
        this.setState({closeImage: close_inactive});
    }

    validateDataUser(){
        firebase.firestore()
        .collection('useradmin').get()
        .then(snapshot => {
            let emailAdmin = '';
            snapshot.forEach(item => {
                emailAdmin = item.data().email;
            })
            if(firebase.auth().currentUser != null){
                if(emailAdmin === firebase.auth().currentUser.email){
                    this.setState({
                        btnClose: (
                            <Link to={`/logout/`} className="btn-close">
                                <span style={{marginRight: 10}}>Cerrar Sesión</span>
                                <Image src={this.state.closeImage} 
                                    onMouseEnter={this.onMouseEnter} 
                                    onMouseOut={this.onMouseOut} 
                                    style={{width: 16}}
                                />
                            </Link>
                        )
                    });
                }else{
                    this.setState({
                        btnClose: (<div/>)
                    })
                }
            }
        })
    }

    render(){
        const { origin } = this.props;
        let closesesion = (<div/>);
        // if(firebase.auth().currentUser != null){
        //     this.validateDataUser();
        // }
        return(
            <Row className="header-block">
                <Col>
                    <div>
                        <Image src={logo} style={{width: 170}}/>
                    </div>
                    {this.state.btnClose}
                </Col>
            </Row>
        )
    }
}