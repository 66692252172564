import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WrapperComponent from '../utils/WrapperComponent';
import Login from '../screens/auth/Login';
import Migration from '../screens/auth/Migration';
import Evaluation from '../screens/auth/Evaluation';
import Candidates from '../screens/migration/Candidates';
import Header from '../components/Header';
import Footer from '../components/Footer';


export default class Guest extends Component{

    dimensionWindow() {
        let tam = {width: 0, height: 0};
        if (typeof window.innerWidth !== 'undefined')
        {
          tam = {width: window.innerWidth, height: window.innerHeight};
        }
        else if (typeof document.documentElement !== 'undefined'
            && typeof document.documentElement.clientWidth !==
            'undefined' && document.documentElement.clientWidth !== 0)
        {
          tam = {
              width: document.documentElement.clientWidth,
              height: document.documentElement.clientHeight
          };
        }
        else   {
          tam = {
              width: document.getElementsByTagName('body')[0].clientWidth,
              height: document.getElementsByTagName('body')[0].clientHeight
          };
        }
        return tam;
    }

    render(){
        const dimensions = this.dimensionWindow();
        return(
            <BrowserRouter>
                <Container fluid>
                    <Header/>
                    <Row xs={12} md={12} lg={12} className="body-block">
                        <Col>
                            <Routes>
                                {/* <Route exact path="/" component={Wellcome} /> */}
                                <Route exact path="/" element={<WrapperComponent item={Login}/>} />
                                <Route path="/link/:evaluation" element={<WrapperComponent item={Evaluation}/>} />
                                <Route exact path="/migration" element={<WrapperComponent item={Migration}/>} />
                                <Route exact path="/candidate/:code" element={<WrapperComponent item={Candidates}/>} />
                            </Routes>
                        </Col>
                    </Row>
                    <Footer styleFooter="footer-block"/>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        />
                </Container>
            </BrowserRouter>
        )
    }
}
