import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, CircularProgress, Button } from '@mui/material';
import { green } from '@mui/material/colors';

// import { withStyles } from '@material-ui/core/styles';
// import { green } from '@material-ui/core/colors';
// import Grid from '@material-ui/core/Grid';
// import TextField from '@material-ui/core/TextField';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import '../../App.css';

import const_values from '../../utils/ConstValues';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import firebaseConfig from '../../utils/Firebase';
let secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");

// const styles = theme => ({
//     root: {
//       flexGrow: 1,
//       marginTop: 20
//     },
//     paper: {
//         height: 50,
//         width: 200,
//         textAlign: 'center',
//         paddingTop: 10,
//         backgroundColor: '#E6E6E6',
//         cursor: 'pointer',
//         color: '#000'
//     },
//     formControl: {
//         margin: theme.spacing(1),
//         minWidth: 120,
//     },
//     selectEmpty: {
//         marginTop: theme.spacing(2),
//     },
//     control: {
//       padding: theme.spacing(2),
//     },
//     wrapper: {
//         margin: theme.spacing(1),
//         position: 'relative',
//     },
//     buttonSuccess: {
//         backgroundColor: green[500],
//         '&:hover': {
//           backgroundColor: green[700],
//         },
//     },
//     fabProgress: {
//         color: green[500],
//         position: 'absolute',
//         top: -6,
//         left: -6,
//         zIndex: 1,
//     },
//     buttonProgress: {
//         color: green[500],
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         marginTop: -12,
//         marginLeft: -12,
//     },
// });

class UpdateMigration extends Component{
    
    constructor(){
        super();
        this.state={
            tables: [],
            selectTable: '',
            loading: false,
            success: false,
            evaluationId: ''
        }
        this.handleChange = this.handleChange.bind();
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount(){
        this.getTablesMysql();
    }

    getTablesMysql(){
        fetch(const_values.SERVICE_CONNECT+'show_tables')
        .then(res => res.json())
        .then((data) => {
            let tables = [];
            data.forEach(item => {
                tables.push(item.Tables_in_eintegri_encuestas);
                // tables.push(item.Tables_in_eintegri_sistema);
                // tables.push(item.Tables_in_eintegri_sistema);
            })
            this.setState({tables})
        })
        .catch(console.error)
    }    

    selectOption = (value) => () => {
        this.setState({loading: true, success: false});
        this.migrateTable(value);
    }

    migrateTable(table){
        if(table == 'resultados_evaluacion'){
            fetch(const_values.SERVICE_CONNECT+'evaluaciones/get_results_candidate/2290')
            .then(res => res.json())
            .then((data) => {
                // let dataFormat = [];
                // this.saveFirebase(table, dataFormat);
            })
            .catch(console.error)
        }else{
            fetch(const_values.SERVICE_CONNECT+'tablas_simples/'+table)
            .then(res => res.json())
            .then((data) => {
                let dataFormat = [];
                if(table === 'candidatos'){
                    dataFormat = this.generateDataCandidate(data, 'identificacion');
                }else{
                    dataFormat = this.generateData(data, 'id');
                }
                this.saveFirebase(table, dataFormat);
            })
            .catch(console.error)
        }
    }

    generateData(data, primaryKey){
        let dataFormat = [];
        let fields = Object.keys(data[0]);
        data.forEach(item => {
            let register = {};
            fields.forEach(field => {
                if(field !== primaryKey){
                    register[field] = item[field];
                }
            })

            dataFormat.push({
                [item[primaryKey]]: register
            })
        });
        return dataFormat;
    }

    generateDataCandidate(data, primaryKey){
        let dataFormat = [];
        let fields = Object.keys(data[0]);
        data.forEach(item => {
            let register = {};
            if(item.estado === '1' && item.nombre === ''){
                fields.forEach(field => {
                    register[field] = item[field];
                })
                dataFormat.push({
                    [item[primaryKey]]: register
                })
            }

        });
        return dataFormat;
    }

    saveFirebase(table, data){
        data.forEach((item, index) => {
            let id = Object.keys(item)[0];
            let value = Object.values(item)[0];
            let indexValid = index + 1;
            if(indexValid === data.length){
                this.save(table, id, value, true);
            }else{
                this.save(table, id, value, false);
            }
        })
    }

    save(collection, id, data, showMessage){
        if(collection === 'candidatos'){
            this.registerCandidate(id, data, showMessage);
        }else{
            firebase.firestore()
            .collection(collection)
            .doc(id)
            .set(data)
            .then((docRef) => {
                if(showMessage){
                    toast.info('Regitro almacenado correctamente');
                    this.setState({loading: false, success: true});
                    this.props.navigate('/migration');
                }
            });
        }
    }

    registerCandidate(id, data, showMessage){
        secondaryApp.auth().createUserWithEmailAndPassword(data.email, id)
        .then(()=>{
            secondaryApp.auth().signOut();
            if(showMessage){
                toast.info('Regitro almacenado correctamente');
                this.setState({loading: false, success: true});
                this.props.navigate('/migration');
            }
        })
        .catch((err) => {
            console.error(err);
            this.setState({loading: false, success: true});
            if(err.code === 'auth/email-already-in-use'){
                toast.error('La dirección de correo electrónico ya está siendo utilizada por otra cuenta.');
            }else{
                toast.error('Error al registrar el usuario: '+id);
            }
        })
    }

    handleChange = (event) => {
        this.setState({selectTable: event.target.value});
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    insertResults = () => () => {
        let evaluation_id = this.state.evaluationId;
        const resultDocument = firebase.firestore()
            .collection('resultados_evaluacion')
            .doc(evaluation_id);

        resultDocument.collection('resultados')
        .get()
        .then((snapshot) => {
            let data = [];
            snapshot.forEach(item => {
                data.push(item.data());
            })
            let results = this.generateCandidateResults(data);
            this.saveTotals(results);
            // data.forEach(element => {
            //     delete element.factores_id;
            //     delete element.tipo_factor;
            // })
            // this.saveDataMysql(evaluation_id, data);
        });
    }

    generateCandidateResults(answers_select){
        let candidateResults = [];
        let main_factor_results = {};
        let totalResults = {totalTime: 0, totalPercentage: 0}
        let answerKeys = Object.keys(answers_select);
        answerKeys.forEach((answerKey) => {
            let answer = answers_select[answerKey];
            main_factor_results[answer.factores_id] = {puntaje: 0, tiempo: 0, total: 0};
        })

        answerKeys.forEach((answerKey) => {
            let answer = answers_select[answerKey];
            let qualification = parseFloat(answer.calificacion);
            main_factor_results[answer.factores_id].puntaje += qualification;
            main_factor_results[answer.factores_id].tipo_factor = answer.tipo_factor;
            main_factor_results[answer.factores_id].tiempo += parseFloat(answer.tiempo);
            main_factor_results[answer.factores_id].total++;
            totalResults.totalTime += answer.tiempo;
        })
        let factors = Object.keys(main_factor_results);
        factors.forEach((factor) => {
            let answer = main_factor_results[factor];
            if(answer.tipo_factor !== '2'){
                answer.porcentaje = Math.round((answer.puntaje * 100)/answer.total, 2);
                if(answer.tipo_factor === '0' || answer.tipo_factor === '3'){
                    answer.porcentaje = Math.round(100 - (answer.puntaje/answer.total) * 100, 2);
                }
                answer.candidatos_identificacion = this.state.evaluation.candidatos_identificacion;
                answer.factores_id = factor;
                answer.empresas_ruc = this.state.evaluation.empresas_ruc;
                candidateResults[answer.factores_id] = answer;
            }
        })
        let sumPercentage = 0;
        candidateResults.forEach(result => {
            sumPercentage += result.porcentaje;
        })

        totalResults.totalPercentage = sumPercentage/candidateResults.length;        

        return {candidateResults: candidateResults, totalResults: totalResults};
    }

    saveCandidateResults(candidateResults){
        const candidateResultsDoc = firebase.firestore()
            .collection('resultados_candidato')
            .doc(this.props.params.evaluation_id);

        let batch = firebase.firestore().batch();

        let factors = Object.keys(candidateResults);
        factors.forEach((factor) => {
            if(candidateResults[factor].tipo_factor !== '2'){
                let resultadosCollection = candidateResultsDoc.collection('resultados').doc(factor)
                batch.set(resultadosCollection, candidateResults[factor])
            }
        })
        batch.commit().then((docRef) => {
            // toast.info('Registros guardados con exito');
            if(this.state.evaluation.test_iq == '1'){
                let factorsIQSize = this.state.factorsIq.length;
                if(this.countFactors == factorsIQSize){
                    this.saveStatusEvaluationMysql()
                }else{
                    toast.info('Registros guardados con exito');
                    // this.setState({loading: false, buttonDisabled: false});
                    // // this.cambiar_pantalla(7);
                }
            }else{
                this.saveStatusEvaluationMysql()
            }
        });
    }

    saveTotals(data){
        firebase.firestore()
        .collection('totals')
        .doc(this.props.params.evaluation_id)
        .set(data.totalResults)
        .then(() => {
            this.saveCandidateResults(data.candidateResults);
        });
    }

    saveStatusEvaluationMysql(){
        let data = {
            evaluation_id: this.props.params.evaluation_id,
            status: '3'
        }
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/evaluation_status', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                toast.info('Registros guardados con exito');
                // this.setState({loading: false, buttonDisabled: false});
                // this.cambiar_pantalla(7);
            }else{
                toast.error(data.text);
                // this.setState({loading: false, buttonDisabled: false});
                // this.props.navigate('/list_evaluations/'+this.state.evaluation.candidatos_identificacion);
            }
        })
        .catch(err =>{
            toast.error(err);
            this.setState({loading: false, buttonDisabled: false});
        })
    }

    saveDataMysql(evaluation_id, evaluationResults){
        let data = {
            evaluation_canditate_id: evaluation_id,
            evaluationResults: evaluationResults
        }
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/evaluation_completed', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            console.log(data);
        })
        .catch(err =>{
            toast.error(err);
            this.setState({loading: false, buttonDisabled: false});
        })
    }

    allResults = () => () => {
        this.generateEvaluations();
    }

    generateEvaluations(){
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/massive/1760013480001')
        .then(res => res.json())
        .then((data) => {
            if(data.status === undefined){
                let time = parseInt(data[0].valor) * 1000;
                this.setState({question_time: time});
            }else{
                toast.error('Los datos de evaluación no se han cargado correctamente');
            }
        })
        .catch(console.error)
    }

    render(){
        // const { classes } = this.props;
        
        return(
            <Grid container style={{flexGrow: 1, marginTop: 20}}  spacing={2}>
                <Grid item xs={12}>
                    <div style={{textAlign: 'center', width: '100%', marginBottom: 20}}>
                        Seleccione la tabla que desea migrar:
                    </div>
                    <div>
                        <TextField 
                            type="text" 
                            id="evaluationId" 
                            name="evaluationId" 
                            label="Evalucion id" 
                            variant="outlined"
                            fullWidth
                            value={this.state.evaluationId} 
                            onChange={this.handleInputChange} 
                        />
                        <Button className="button" variant='primary' onClick={this.insertResults()}>Insertar resultados</Button>
                        <Button className="button" variant='primary' onClick={this.allResults()}>All resultados</Button>
                    </div>
                    <Grid container justify="center" spacing={2}>
                        {this.state.tables.map((value, index) => (
                            <Grid key={index} item>
                                {/* <Paper className={classes.paper} onClick={this.selectOption(value)}>
                                    {value}
                                </Paper> */}
                                <div style={{position: 'relative'}}>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        height: 50,
                                        width: 200,
                                        textAlign: 'center',
                                        paddingTop: 10,
                                        backgroundColor: '#E6E6E6',
                                        cursor: 'pointer',
                                        color: '#000'
                                    }}
                                    disabled={this.state.loading}
                                    onClick={this.selectOption(value)}
                                    >
                                    {value}
                                    </Button>
                                    {this.state.loading && <CircularProgress 
                                        size={24} 
                                        style={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: -12,
                                            marginLeft: -12,
                                        }}
                                    />}
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

// UpdateMigration.propTypes = {
//     classes: PropTypes.object.isRequired
// }

export default UpdateMigration;
