import React, {Component} from 'react';
import { toast } from 'react-toastify';
import '../../App.css';

import const_values from '../../utils/ConstValues';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import firebaseConfig from '../../utils/Firebase';
let secondaryApp = firebase.initializeApp(firebaseConfig, "Thrith");

export default class Candidate extends Component{
    
    constructor(){
        super();
        this.state={
            tables: [],
            selectTable: '',
            loading: false,
            success: false
        }
    }

    componentDidMount(){
        let parameter = this.props.params;
        let codeEvaluation = atob(parameter.code);
        this.migrateTable(codeEvaluation)
    }

    migrateTable(codeEvaluation){
        fetch(const_values.SERVICE_CONNECT+'candidatos/by_evaluation/'+codeEvaluation)
        .then(res => res.json())
        .then((data) => {
            let dataFormat = this.generateDataCandidate(data, 'identificacion');
            this.saveFirebase(dataFormat);
        })
        .catch(console.error)
    }

    generateDataCandidate(data, primaryKey){
        let dataFormat = [];
        let fields = Object.keys(data[0]);
        data.forEach(item => {
            let register = {};
            if(item.estado === '1' && item.nombre === ''){
                fields.forEach(field => {
                    register[field] = item[field];
                })
                dataFormat.push({
                    [item[primaryKey]]: register
                })
            }

        });
        return dataFormat;
    }

    saveFirebase(data){
        data.forEach((item, index) => {
            let id = Object.keys(item)[0];
            let value = Object.values(item)[0];
            let indexValid = index + 1;
            if(indexValid === data.length){
                this.registerCandidate(id, value, true);
            }else{
                this.registerCandidate(id, value, false);
            }
        })
    }

    registerCandidate(id, data, showMessage){
        secondaryApp.auth().createUserWithEmailAndPassword(data.email, id)
        .then(()=>{
            secondaryApp.auth().signOut();
            if(showMessage){
                toast.info('Regitro almacenado correctamente');
                this.setState({loading: false, success: true});
                this.props.navigate('/migration');
            }
        })
        .catch((err) => {
            console.error(err);
            this.setState({loading: false, success: true});
            if(err.code === 'auth/email-already-in-use'){
                toast.error('La dirección de correo electrónico ya está siendo utilizada por otra cuenta.');
            }else{
                toast.error('Error al registrar el usuario: '+id);
            }
        })
    }

    render(){
        return(
            <div>Generar candidatos</div>
        )
    }
}
