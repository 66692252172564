export default { questions: [
    {
        pregunta: '¿Entiende que las preguntas tienen un tiempo límite para responder?',
        con_caso: 0,
        mostrar_pregunta: true,
        con_argumento: 0,
        mostrar_argumento: false,
        re_pregunta: '',
        caso: '',
        respuestas: [
            {respuesta: 'Si',re_pregunta: '', showAnswer: true},
            {respuesta: 'No',re_pregunta: '', showAnswer: true},
            {respuesta: 'No estoy seguro',re_pregunta: '', showAnswer: true}
        ]
    },
    {
        pregunta: '¿Entiende como funcionan estas preguntas?',
        con_caso: 1,
        mostrar_pregunta: false,
        con_argumento: 0,
        mostrar_argumento: false,
        re_pregunta: '',
        caso: 'Existirán casos que necesitará analizar antes de responder, estas se mostrarán en esta sección. Cuando esté seguro de haber entendido, puede presionar en el botón Continuar y se mostrará la barra del tiempo.',
        respuestas: [
            {respuesta: 'Si',re_pregunta: '', showAnswer: true},
            {respuesta: 'No',re_pregunta: '', showAnswer: true},
            {respuesta: 'No estoy seguro',re_pregunta: '', showAnswer: true}
        ]
    },
    {
        pregunta: '¿Usted sabe de qué color es la banana?',
        con_caso: 0,
        mostrar_pregunta: true,
        con_argumento: 1,
        mostrar_argumento: false,
        re_pregunta: '',
        caso: '',
        respuestas: [
            {respuesta: 'Si',re_pregunta: 'Puede indicar de que color es:', showAnswer: true},
            {respuesta: 'No',re_pregunta: '', showAnswer: true},
        ]
    }
]}