import constValues from "./ConstValues";

export default {
    verbal: {
        instruction: "Te mostraremos una palabra y cuatro opciones de respuesta, deberás seleccionar la palabra que tenga similar significado.",
        note: "Recuerda, solamente hay una respuesta correcta.",
        question: {pregunta:"BLANCO",imagen:"", width: 0},
        widthQuestion: 2,
        answers: [
            {respuesta:"Oscuro",imagen:"", checked: false},
            {respuesta:"Claro",imagen:"", checked: true},
            {respuesta:"Vigoroso",imagen:"", checked: false},
            {respuesta:"Fuerte",imagen:"", checked: false},
        ]
    },
    numerica: {
        instruction: "Te mostraremos una operación matemática, realizala rápidamente y responde si es correcto o incorrecto.",
        note: "Recuerda, solamente hay una respuesta correcta.",
        question: {pregunta:"",imagen:"numerico.jpeg", width: 90},
        widthQuestion: 3,
        answers: [
            {respuesta:"Correcto",imagen:"", checked: true},
            {respuesta:"Incorrecto",imagen:"", checked: false},
        ]
    },
    espacial: {
        instruction: "Te mostraremos una imagen y seis opciones de respuesta, deberás seleccionar la imagen que sea exactamente igual, para ello deberás voltear las mismas en tu mente.",
        note: "Recuerda, solamente hay una respuesta correcta, en este caso la opción 3.",
        question: {pregunta:"",imagen:"espacial.png", width: 37},
        widthQuestion: 2,
        answers: [
            {respuesta:"",imagen:"espacial1.png", checked: false, width: 37},
            {respuesta:"",imagen:"espacial2.png", checked: false, width: 37},
            {respuesta:"",imagen:"espacial3.png", checked: true, width: 37},
            {respuesta:"",imagen:"espacial4.png", checked: false, width: 37},
            {respuesta:"",imagen:"espacial5.png", checked: false, width: 37},
            {respuesta:"",imagen:"espacial6.png", checked: false, width: 37},
        ]
    },
    abstracta: {
        instruction: "Te mostraremos una imagen y seis opciones de respuesta, deberás seleccionar la imagen que complete el orden lógico.",
        note: "Recuerda, solamente hay una respuesta correcta, en este caso la opción 5.",
        question: {pregunta:"BLANCO",imagen:"abstracta.png", width: 190},
        widthQuestion: 4,
        answers: [
            {respuesta:"",imagen:"abstracta1.png", checked: false, width: 37},
            {respuesta:"",imagen:"abstracta2.png", checked: false, width: 37},
            {respuesta:"",imagen:"abstracta3.png", checked: false, width: 37},
            {respuesta:"",imagen:"abstracta4.png", checked: false, width: 37},
            {respuesta:"",imagen:"abstracta5.png", checked: true, width: 37},
            {respuesta:"",imagen:"abstracta6.png", checked: false, width: 37},
        ]
    },
    logica: {
        instruction: "Te mostraremos una secuencia de letras y seis opciones de respuesta, deberás seleccionar la letra que siga lógicamente la secuencia.",
        note: "Recuerda, solamente hay una respuesta correcta, en este caso, la D, debido a que la secuencia lógica es repetir dos veces cada letra.",
        question: {pregunta:"AABBCCD",imagen:""},
        widthQuestion: 2,
        answers: [
            {respuesta:"A",imagen:"", checked: false},
            {respuesta:"E",imagen:"", checked: false},
            {respuesta:"D",imagen:"", checked: true},
            {respuesta:"B",imagen:"", checked: false},
            {respuesta:"C",imagen:"", checked: false},
            {respuesta:"F",imagen:"", checked: false},
        ]
    },
}
