import {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default class Logout extends Component {

	componentDidMount () {
		firebase.auth().signOut()
			.then(() => {
                this.props.navigate('/');
			})
			.catch(error => {
                console.error(error);
                this.props.navigate('/');
			})
	}

	render () {
		return null;
	}
}