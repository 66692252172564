import React, {Component} from 'react';
import '../App.css';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';

export default class CheckEvaluation extends Component{

    render(){
        const { 
            status,
            action,
            text
        } = this.props;
        return(
            <FormControlLabel 
                control={
                    <Checkbox color="primary" 
                        checked={status}
                        onChange={action}
                    />
                } 
                label={<Typography className='check-consent'>{text}</Typography>} 
            />
        )
    }
}