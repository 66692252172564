import React from 'react';
import './App.css';
import Guest from './navigation/Guest';
import Logged from './navigation/Logged';
import Admin from './navigation/Admin';
import Preloader from './components/Preloader';

import firebaseConfig from './utils/Firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
const firebaseApp = firebase.initializeApp(firebaseConfig);

export default class App extends React.Component {

  constructor(){
    super();
    this.state = {
      isLogged: false,
      isAdmin: false,
      loader: false
    }
  }

  componentDidMount(){
    firebaseApp.auth().onAuthStateChanged((user) => {
      if(user !== null){
        this.validateDataUser();
      }else{
        this.setState({isLogged: false, isAdmin: false, loader: true});
      }
    })
  }

  validateDataUser(){
    firebase.firestore()
    .collection('useradmin').get()
    .then(snapshot => {
      let emailAdmin = '';
      snapshot.forEach(item => {
        emailAdmin = item.data().email;
      })
      if(emailAdmin === firebase.auth().currentUser.email){
        this.setState({isLogged: false, isAdmin: true, loader: true});
      }else{
        this.setState({isLogged: true, isAdmin: false, loader: true});
      }
    })
  }

  render(){
    const {loader, isLogged, isAdmin} = this.state;
    console.log(loader, isLogged, isAdmin);
    if(!loader){
      return (<Preloader/>);
    }else{
      if(isLogged){
        return (<Logged/>);
      }else{
        if(isAdmin){
          return (<Admin/>);
        }else{
          return (<Guest/>);
        }
      }
    }
  }
}