import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WrapperComponent from '../utils/WrapperComponent';
import Logout from '../screens/auth/Logout';
import RegisterCandidate from '../screens/register/RegisterCandidate';
import ListEvaluation from '../screens/evaluation/ListEvaluation';
import Evaluation from '../screens/evaluation/Evaluation';
import EvaluationIQ from '../screens/evaluation/EvaluationIQ';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ScrollToTop from '../utils/ScrollToTop';

export default class Logged extends Component{

    dimensionWindow() {
        let tam = {width: 0, height: 0};
        if (typeof window.innerWidth !== 'undefined')
        {
          tam = {width: window.innerWidth, height: window.innerHeight};
        }
        else if (typeof document.documentElement !== 'undefined'
            && typeof document.documentElement.clientWidth !==
            'undefined' && document.documentElement.clientWidth !== 0)
        {
          tam = {
              width: document.documentElement.clientWidth,
              height: document.documentElement.clientHeight
          };
        }
        else   {
          tam = {
              width: document.getElementsByTagName('body')[0].clientWidth,
              height: document.getElementsByTagName('body')[0].clientHeight
          };
        }
        return tam;
    }

    render(){
        const dimensions = this.dimensionWindow();
        let heightBlock = dimensions.height - 68 - 64;
        return(
            <BrowserRouter>
                <Container fluid>
                    <Header origin='test' />
                    <ScrollToTop/>
                    <div>
                        <Row className="body-block">
                            <Col>
                                <Routes>
                                    {/* <Route exact path="/" component={Wellcome} /> */}
                                    <Route exact path="/" element={<WrapperComponent item={RegisterCandidate}/>} />
                                    <Route exact path="/link/:evaluation" element={<WrapperComponent item={RegisterCandidate}/>} />
                                    <Route exact path="/list_evaluations/:candidate" element={<WrapperComponent item={ListEvaluation}/>} />
                                    <Route exact path="/evaluation/:evaluation_id" element={<WrapperComponent item={Evaluation}/>} />
                                    <Route exact path="/evaluation_iq/:evaluation_id/:factor_id" element={<WrapperComponent item={EvaluationIQ}/>} />
                                    <Route exact path="/logout/" element={<WrapperComponent item={Logout}/>} />
                                </Routes>
                            </Col>
                        </Row>
                    </div>
                    <Footer styleFooter="footer-block"/>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Container>
            </BrowserRouter>
        )
    }
}
