import 'date-fns';
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, TextField, InputLabel, MenuItem, FormControl, Select, Button } from '@mui/material';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';

import const_values from '../../utils/ConstValues';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// const styles = theme => ({
//     root: {
//       flexGrow: 1,
//       marginTop: 20
//     },
//     selectEmpty: {
//         marginTop: theme.spacing(2),
//     },
//     control: {
//       padding: theme.spacing(2),
//     },
// });

class RegisterCandidate extends Component{

    constructor(){
        super();
        this.state={
            document_type: '',
            document: '',
            first_name: '',
            second_name: '',
            surname: '',
            second_surname: '',
            birthday: '',
            gender: '',
            country: '',
            province: '',
            phone: '',
            email: '',
            level_instruction: '',
            countries: [],
            provinces: [],
            candidate: '',
            number_candidate: 0,
            evaluation: '',
            isResgister: false,
            isValidEmail: false,
            isByLink: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputDocument = this.handleInputDocument.bind(this);
        this.register = this.register.bind(this);
    }

    componentDidMount(){
        if(firebase.auth().currentUser){
            if(firebase.auth().currentUser.email !== null){
                this.setState({email: firebase.auth().currentUser.email});
                this.getCandidateByEmail(firebase.auth().currentUser.email);
            }else{
                this.setState({isByLink: true});
                let code = this.props.params.evaluation;
                this.getEvaluation(code);
            }
            this.getNumberCandidate();
            this.getCountries();
        }
    }

    getEvaluation(code){
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/by_codigo/'+code)
        .then(res => res.json())
        .then((data) => {
            if(data.status === undefined){
                this.setState({evaluation: data[0]})
            }
        })
        .catch(console.error)
    }

    getCountries(){
        firebase.firestore()
        .collection('paises')
        .onSnapshot(snapshot => {
            let countries = [];
            snapshot.forEach(element => {
                let item = {
                    name: element.data().nombre,
                    id: element.id
                }
                countries.push(item);
            })  
            this.setState({countries}); 
        })
    }

    getProvinces(country_id){
        firebase.firestore()
        .collection('provincias')
        .where('paises_id', '==', country_id)
        .onSnapshot(snapshot => {
            let provinces = [];
            snapshot.forEach(element => {
                let item = {
                    name: element.data().nombre,
                    id: element.id
                }
                provinces.push(item);
            })  
            this.setState({provinces}); 
        })
    }

    getCandidateByEmail(email){
        let data = {email: email};
        fetch(const_values.SERVICE_CONNECT+'candidatos/by_email', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                this.setState({candidate: data.text});
                if(data.text.documento != ""){
                    this.setState({isValidEmail: true});
                }
                this.setDataCandidate(data.text);
            }
        })
        .catch(console.error)
    }

    getCandidateByDocument(documento){
        let data = {documento: documento};
        fetch(const_values.SERVICE_CONNECT+'candidatos/by_document', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                if(firebase.auth().currentUser.email != null){
                    toast.error('El documento ingresado ya se encuentra registrado en otro usuario')
                    this.setState({
                        document_type: '',
                        document: '',
                    })
                }else{
                    this.setState({
                        candidate: data.text,
                        isValidEmail: true
                    });
                    this.setDataCandidate(data.text);
                }      
            }else{
                this.setState({isValidEmail: true});
            }
        })
        .catch(console.error)
    }

    setDataCandidate(data){
        let names = data.nombre.split(' ');
        let surnames = data.apellido.split(' ');
        if(data.paises_id !== 0 && data.paises_id !== null && data.paises_id !== undefined){
            this.getProvinces(data.paises_id);
        }
        this.setState({
            document_type: data.tipo_documento,
            document: data.documento,
            first_name: names[0],
            second_name: names[1],
            surname: surnames[0],
            second_surname: surnames[1],
            birthday: data.fecha_nacimiento,
            gender: data.genero,
            country: parseInt(data.paises_id),
            province: parseInt(data.provincias_id),
            phone: data.telefono,
            email: data.email,
            level_instruction: data.nivel_instruccion,
        })
    }

    getNumberCandidate(){
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/number_candidate')
        .then(res => res.json())
        .then((data) => {
            this.setState({number_candidate: data});
        })
        .catch(console.error)
    }
    
    handleInputDocument(event) {
        if(!this.state.isValidEmail){
            this.getCandidateByDocument(this.state.document);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(name === 'country'){
            this.getProvinces(value);
        }

        this.setState({
            [name]: value
        });
    }

    register(){
        if(this.validateFields()){
            this.setState({isResgister: true});
            let data = this.generateData();
            this.saveMysql(data);
        }
    }

    generateData(){
        let number = this.state.number_candidate++;
        // let identificacion = 'C'+this.str_pad(number.toString(), 6, "0", 'STR_PAD_LEFT');
        let identificacion = '';
        let contrasena = '';
        if(this.state.candidate !== ''){
            identificacion = this.state.candidate.identificacion;
            contrasena = this.state.candidate.contrasena;
        }
        
        let actualDate = new Date();
        let actualYear = actualDate.getFullYear();
        
        let birthday = new Date(this.state.birthday);
        let birthdayYear = birthday.getFullYear();
        let edad = actualYear - birthdayYear;

        let codigo = ''; 
        let empresas_ruc = ''; 
        if(this.state.evaluation != ''){
            codigo = this.state.evaluation.codigo; 
            empresas_ruc = this.state.evaluation.empresas_ruc; 
        }

        let nombre = this.state.first_name;
        if(this.state.second_name != '' && this.state.second_name != undefined){
            nombre += ' '+this.state.second_name;
        }
        
        let apellido = this.state.surname;
        if(this.state.second_surname != '' && this.state.second_surname != undefined){
            apellido += ' '+this.state.second_surname;
        }

        let data = {
            identificacion: identificacion,
            nombre: nombre,
            apellido: apellido,
            edad: edad,
            genero: this.state.gender,
            email: this.state.email,
            telefono: this.state.phone,
            contrasena: contrasena,
            paises_id: this.state.country,
            documento: this.state.document,
            tipo_documento: this.state.document_type,
            fecha_nacimiento: this.state.birthday,
            provincias_id: this.state.province,
            nivel_instruccion: this.state.level_instruction,
            empresas_ruc: empresas_ruc,
            codigo: codigo 
        }
        return data;
    }

    saveFirebase(id_user, data){
        delete data.empresas_ruc;
        delete data.codigo;
        firebase.firestore()
        .collection('candidatos')
        .doc(id_user)
        .set(data)
        .then((docRef) => {
            this.setState({isResgister: false});
            this.props.navigate('/list_evaluations/'+id_user);
        });
    }

    saveMysql(register){
        let data = {data: register};
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/save_candidate', {
            method: 'POST',
            body: JSON.stringify(data), 
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                // this.setState({candidate: data.text});
                this.saveFirebase(data.text, register);
            }else{
                toast.error(data.text);
            }
        })
        .catch(console.error)
    }

    validateFields(){
        let validation = true;
        let contObligatory = 0;
        if(this.state.document_type === '' || this.state.document === ''){
            contObligatory++;
            validation = false;
        }

        if(this.state.first_name === '' || this.state.surname === ''){
            contObligatory++;
            validation = false;
        }

        if(this.state.birthday === '' || this.state.gender === '' || 
            this.state.country === '' || this.state.province === '' ||
            this.state.level_instruction === ''){
            contObligatory++;
            validation = false;
        }

        if(this.state.email === ''){
            contObligatory++;
            validation = false;
        }else{
            let validEmail = /@/.test(this.state.email);
            if(!validEmail){
                toast.error('Email incorrecta');
                validation = false;
            }
        }

        if(contObligatory > 0){
            toast.error('Todos los campos del formulario son obligatorios');
        }
        return validation;
    }

    str_pad(str, pad_length, pad_string, pad_type){
        let len = pad_length - str.length;
        if(len < 0) return str;
        let pad = new Array(len + 1).join(pad_string);
        if(pad_type === "STR_PAD_LEFT") return pad + str;
        return str + pad;
    }

    clearFields(){
        this.setState({
            document_type: '',
            document: '',
            first_name: '',
            second_name: '',
            surname: '',
            second_surname: '',
            birthday: '',
            gender: '',
            country: '',
            province: '',
            phone: '',
            email: '',
            level_instruction: '',
            countries: [],
            provinces: [],
            candidate: '',
            number_candidate: 0,
            evaluation: ''
        });
    }

    render(){
        // const { classes } = this.props;
        return(
            <Grid container spacing={2}>
                <div className="register-div">
                    <div className="div-element">
                        <h6>Por favor, completa los siguientes datos antes de dar inicio la evaluación.</h6>
                    </div>
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth margin="dense" size="small">
                                <InputLabel id="document_type-label">Tipo de documento</InputLabel>
                                <Select
                                    labelId="document_type-label"
                                    id="document_type"
                                    name="document_type"
                                    value={this.state.document_type}
                                    onChange={this.handleInputChange}
                                    label="Tipo de documento"
                                >
                                <MenuItem value={'Cédula'}>Cédula</MenuItem>
                                <MenuItem value={'DNI'}>DNI</MenuItem>
                                <MenuItem value={'Pasaporte'}>Pasaporte</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                type="text" 
                                id="document" 
                                name="document" 
                                label="Documento" 
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={this.state.document} 
                                onChange={this.handleInputChange}
                                inputProps={{maxLength: 10}}
                                onBlur={this.handleInputDocument} 
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={6}>
                            <TextField 
                                type="text" 
                                id="first_name" 
                                name="first_name" 
                                label="Primer nombre" 
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={this.state.first_name} 
                                onChange={this.handleInputChange}
                                disabled={!this.state.isValidEmail} 
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                type="text" 
                                id="second_name" 
                                name="second_name" 
                                label="Segundo Nombre" 
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={this.state.second_name} 
                                onChange={this.handleInputChange}
                                disabled={!this.state.isValidEmail} 
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={6}>
                            <TextField 
                                type="text" 
                                id="surname" 
                                name="surname" 
                                label="Primer apellido" 
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={this.state.surname} 
                                onChange={this.handleInputChange}
                                disabled={!this.state.isValidEmail} 
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                type="text" 
                                id="second_surname" 
                                name="second_surname" 
                                label="Segundo apellido" 
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={this.state.second_surname} 
                                onChange={this.handleInputChange}
                                disabled={!this.state.isValidEmail} 
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={6}>
                            <TextField 
                                type="date" 
                                id="birthday" 
                                name="birthday" 
                                label="Fecha de nacimiento" 
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={this.state.birthday} 
                                onChange={this.handleInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={!this.state.isValidEmail} 
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth margin="dense" size="small">
                                <InputLabel id="gender-label">Genero</InputLabel>
                                <Select
                                    labelId="gender-label"
                                    id="gender"
                                    name="gender"
                                    value={this.state.gender}
                                    onChange={this.handleInputChange}
                                    label="Genero"
                                    disabled={!this.state.isValidEmail}
                                >
                                <MenuItem value={'Masculino'}>Masculino</MenuItem>
                                <MenuItem value={'Femenino'}>Femenino</MenuItem>
                                <MenuItem value={'Sin_definir'}>Prefiero no detallar</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth margin="dense" size="small">
                                <InputLabel id="country-label">País donde vives</InputLabel>
                                <Select
                                    labelId="country-label"
                                    id="country"
                                    name="country"
                                    value={this.state.country}
                                    onChange={this.handleInputChange}
                                    label="País donde vives"
                                    disabled={!this.state.isValidEmail}
                                >
                                    {this.state.countries.map((country, index) => (
                                        <MenuItem key={'C'+index} value={country.id}>{country.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth margin="dense" size="small">
                                <InputLabel id="province-label">Provincia donde vives</InputLabel>
                                <Select
                                    labelId="province-label"
                                    id="province"
                                    name="province"
                                    value={this.state.province}
                                    onChange={this.handleInputChange}
                                    label="Provincia donde vives"
                                    disabled={!this.state.isValidEmail}
                                >
                                    {this.state.provinces.map((province, index) => (
                                        <MenuItem key={'P'+index} value={province.id}>{province.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={6}>
                            <TextField 
                                type="text" 
                                id="phone" 
                                name="phone" 
                                label="Teléfono" 
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={this.state.phone} 
                                onChange={this.handleInputChange} 
                                disabled={!this.state.isValidEmail}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                type="text" 
                                id="email" 
                                name="email" 
                                label="Email" 
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={this.state.email}
                                onChange={this.handleInputChange} 
                                disabled={!this.state.isValidEmail || !this.state.isByLink} 
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth margin="dense" size="small">
                                <InputLabel id="level_instruction-label">Nivel de instrucción</InputLabel>
                                <Select
                                    labelId="level_instruction-label"
                                    id="level_instruction"
                                    name="level_instruction"
                                    value={this.state.level_instruction}
                                    onChange={this.handleInputChange}
                                    label="Nivel de instrucción"
                                    disabled={!this.state.isValidEmail}
                                >
                                <MenuItem value={'Primaria'}>Primaria</MenuItem>
                                <MenuItem value={'Secundaria'}>Secundaria</MenuItem>
                                <MenuItem value={'Superior'}>Superior</MenuItem>
                                <MenuItem value={'Maestría'}>Maestría</MenuItem>
                                <MenuItem value={'Doctorado'}>Doctorado</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                        </Grid>
                    </Grid>
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 20, marginTop: 10}}>
                        <Button className="button-register" variant="contained" onClick={this.register.bind()}
                            disabled={this.state.isResgister || !this.state.isValidEmail}
                        >
                            Continuar
                        </Button>
                    </div>
                    {/* <Grid container spacing={3}> */}
                        {/* <Grid item xs={6}></Grid> */}
                        {/* <Grid item xs={12} justifyContent={'flex-end'}> */}
                            
                        {/* </Grid> */}
                    {/* </Grid> */}
                </div>
                <Link to={`/logout/`}>Salir</Link>
            </Grid>
        )
    }
}

// RegisterCandidate.propTypes = {
//     classes: PropTypes.object.isRequired
// }

export default RegisterCandidate;