import React, {Component} from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Grid, LinearProgress, Button, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

// import { withStyles } from '@material-ui/core/styles';
// import { blue } from '@material-ui/core/colors';
// import Grid from '@material-ui/core/Grid';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import Button from '@material-ui/core/Button';
// import { css } from "@emotion/core";
import { PropagateLoader } from "react-spinners";
import Webcam from "react-webcam";
// import {AES, enc} from "crypto-js";

import '../../App.css';

import Preloader from '../../components/Preloader';
import Question from '../../components/Question';
import CheckEvaluation from '../../components/CheckEvaluation';
import SequenceEvaluation from '../../components/SequenceEvaluation';
import const_values from '../../utils/ConstValues';
import simulateQuestions from '../../utils/simulateQuestions';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// const styles = theme => ({
//     root: {
//       width: '100%',
//     },
//     barColorPrimary: {
//         backgroundColor: blue[500]
//     },
//     colorPrimary: {
//         backgroundColor: blue[100]
//     }
// });

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};
// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

const QuestionLinearProgress = styled(LinearProgress)(({ theme }) => ({
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

class Evaluation extends Component{

    constructor(){
        super();
        this.state={
            visible: [true, false, false, false, false, false, false, false],
            // visible: [false, false, false, true, false, false, false, false],
            evaluation: '',
            questions: [],
            number_questions: 0,
            count_time: 0,
            percentage_questions: 0,
            answers_select: [],
            empty_aswer: '',
            loading: false,
            loadingInit: false,
            loaderActive: 'loader-inactive',
            company_aswers: [],
            buttonDisabled: false,
            resultsMysql: {
                candidateResults: [],
                evaluationResults: []
            },
            capturesAnswer: [],
            showCam: false,
            question_time: "30000",
            blockQuestion: false,
            blockStartEval: false,
            factorsIq: [],
            isAuthorizePolygraph: true,
            isConfidentiality: true,
            isInstructions: true,
            simulateQuestion: simulateQuestions.questions,
            argumentText: '',
            answerArgument: ''
        }
        
        this.count_questions = 0;
        this.count_simulate = 0;
        this.interval = 0;
        this.time_simulation = "15000";
        this.salir = this.salir.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleLeavePage = this.handleLeavePage.bind(this);
        this.withoutActivity = 0;
        this.countFactors = 0;
    }

    componentDidMount(){
        
        window.location.hash="no-back-button";
        window.location.hash="Again-No-back-button";//esta linea es necesaria para chrome
        window.onhashchange=function(){window.location.hash="no-back-button";}

        this.callWindowMouseout();
        // window.addEventListener('beforeunload', this.handleLeavePage);
        let evaluation_id = this.props.params.evaluation_id;
        this.setState({loadingInit: false});
        this.getEvaluation(evaluation_id);
    }

    componentWillUnmount(){
        // window.removeEventListener('beforeunload', this.handleLeavePage);
    }

    windowMouseout( obj, evt, fn ) {
        if ( obj.addEventListener ) {
            obj.addEventListener( evt, fn, false );
        }
        else if ( obj.attachEvent ) {
            obj.attachEvent( 'on' + evt, fn );
        }
    }

    callWindowMouseout(){
        this.windowMouseout( document, 'mouseout', event => {
            event = event ? event : window.event;
            var from = event.relatedTarget || event.toElement;
            if (!from || from.nodeName === 'HTML') {
                this.updateOutputEvaluation();
            }
        } );
    }

    updateOutputEvaluation(){
        let data = {
            evaluation_id: this.props.params.evaluation_id,
        };
        fetch(const_values.SERVICE_CONNECT+'pruebas/save_unload_evaluation', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((data) => {})
        .catch(console.error)
    }

    handleLeavePage(e) {
        // const confirmationMessage = 'Esta seguro de abandonar esta evaluacion? al salir su evaluacion puede ser registrada como abandonada';
        const confirmationMessage = 'Salir';
        e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
        return confirmationMessage;              // Gecko, WebKit, Chrome <34
    }

    setRef = webcam => {
        this.webcam = webcam;
    };

    getEvaluation(evaluation_id){
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/get_evaluation/'+evaluation_id)
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                this.setState({evaluation: data.text});
                if(data.text.test_iq == '1'){
                    this.getFactorsIQ();
                }else{
                    this.getTimeQuestion();
                }
            }else{
                toast.error('Los datos de evaluación no se han cargado correctamente');
            }
        })
        .catch(console.error)
    }

    getEmptyAswer(){
        fetch(const_values.SERVICE_CONNECT+'pruebas/empty_aswer')
        .then(res => res.json())
        .then((data) => {
            if(data.status === undefined){
                this.setState({empty_aswer: data[0], loadingInit: true});
                this.validFactoresIQ();
                let error_count = localStorage.getItem('error_count');
                if(error_count == 1){
                    this.cambiar_pantalla(4);
                    localStorage.removeItem('error_count');
                }
                // let backAswers = localStorage.getItem('backAswers');
                // if(backAswers != null){
                //     let bytes  = AES.decrypt(backAswers, 'integritas');
                //     let decryptedData = JSON.parse(bytes.toString(enc.Utf8));
                //     this.setState({answers_select: decryptedData});
                //     localStorage.removeItem('backAswers');
                //     this.cambiar_pantalla(6);
                // }
            }else{
                this.messageErrorData('respuesta vacio');
            }
        })
        .catch(console.error)
    }

    getFactorsIQ(){
        fetch(const_values.SERVICE_CONNECT+'factores/byTypeEvaluation/3')
        .then(res => res.json())
        .then((data) => {
            if(data.status === undefined){
                this.setState({factorsIq: data});
                this.getTimeQuestion();
            }else{
                this.messageErrorData(data.text);
            }
        })
        .catch(console.error)
    }
    
    getQuestions(){
        let candidate_id = this.state.evaluation.candidatos_identificacion;
        let cargos_id = this.state.evaluation.cargos_id;
        let empresas_ruc = this.state.evaluation.empresas_ruc;
        fetch(const_values.SERVICE_CONNECT+'pruebas/questions/'+candidate_id+'/'+cargos_id+'/'+empresas_ruc)
        .then(res => res.json())
        .then((data) => {
            if(data.status === undefined){
                if(data.length > 0){
                    let answersList = {};
                    data.forEach(element => {
                        element.mostrar_argumento = false;
                        element.re_pregunta = '';
                        element.mostrar_pregunta = true;
                        if(element.con_caso == '1'){
                            element.mostrar_pregunta = false;
                        }
                        element.respuestas.forEach(respuesta => {
                            respuesta.showAnswer = true;
                        })
                        answersList[element.id] = {
                            evaluacion_candidatos_id: this.props.params.evaluation_id,
                            preguntas_id: element.id,
                            factores_id: element.factores_id,
                            tipo_factor: element.tipo_factor,
                            respuesta: '',
                            calificacion: 0,
                            respuestas_id: '',
                            tiempo: '',
                            captura: '',
                            respuesta_argumento: ''
                        }
                    });
                    this.setState({
                        questions: data,
                        number_questions: data.length,
                        answers_select: answersList
                    });
                    // let questionTest = [];
                    // for (let index = 0; index < 10; index++) {
                    //     questionTest.push(data[index]);
                    // }
                    // questionTest.forEach(element => {
                    //     answersList[element.id] = {
                    //         evaluacion_candidatos_id: this.props.params.evaluation_id,
                    //         preguntas_id: element.id,
                    //         factores_id: element.factores_id,
                    //         tipo_factor: element.tipo_factor,
                    //         respuesta: '',
                    //         calificacion: 0,
                    //         respuestas_id: '',
                    //         tiempo: '',
                    //         captura: ''
                    //     }
                    // });
                    // this.setState({
                    //     questions: questionTest,
                    //     number_questions: questionTest.length,
                    //     answers_select: answersList
                    // });
                    this.getEmptyAswer();
                }else{
                    toast.error('No se pudieron recuperar las preguntas para su evaluación, reportelo a traves de los canales de comunicación de integritas.ec');
                    this.props.navigate('/list_evaluations/'+this.state.evaluation.candidatos_identificacion);
                }
            }else{
                toast.error(data.text);
                this.messageErrorData('preguntas');
            }
        })
        .catch(console.error)
    }

    getTimeQuestion(){
        fetch(const_values.SERVICE_CONNECT+'variables_sistema/get_data_nombre/tiempo_pregunta')
        .then(res => res.json())
        .then((data) => {
            if(data.status === undefined){
                let time = parseInt(data[0].valor) * 1000;
                this.setState({question_time: time});
                this.getQuestions();
            }else{
                toast.error('Los datos de evaluación no se han cargado correctamente');
            }
        })
        .catch(console.error)
    }

    messageErrorData(info){
        toast.error(
            `No se han podido recuperar las ${info}, necesario para rendir la evaluación, por favor informar a la empresa`,
            {onClose: this.salir}
        );
    }

    cambiar_pantalla(index_select) {
        let visibleArray = this.state.visible;
        for (let index = 0; index < visibleArray.length; index++) {
          if(index == index_select){
            visibleArray[index] = true;
          }else{
            visibleArray[index] = false;
          }
        }
        this.setState({visible: visibleArray});
    }

    authorizePolygraph = (answer) => () => {
        this.updateEvaluationCandidateMysql(answer);
    }

    updateEvaluationCandidateMysql(answer){
        let data = {
            evaluation_id: this.props.params.evaluation_id,
            answer: answer
        };
        fetch(const_values.SERVICE_CONNECT+'pruebas/update_evaluate_polygraph', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                this.cambiar_pantalla(1);
            }else{
                toast.error('Regitro almacenado correctamente');
            }
        })
        .catch(console.error)
    }

    confidentialityAgreement = () => () => {
        this.cambiar_pantalla(2);
    }

    simulateEvaluation = () => () => {
        this.cambiar_pantalla(3);
        this.time_simulation = this.state.question_time;
        this.timeLimitSimulation(true);
    }
    
    timeLimitSimulation(simulate){
        let count = 0;
        this.setState({count_time: 0});
        this.interval = setInterval(() => { 
            count += 1000;
            let count_time = (count * 100)/parseInt(this.state.question_time);
            if(count_time > this.state.count_time){
                this.setState({count_time: count_time});
                if(count == parseInt(this.state.question_time)){
                    clearInterval(this.interval);
                    if(simulate){
                        this.ejecuteSimulateAnswer();
                    }else{
                        if((this.count_questions + 1) < this.state.questions.length){
                            this.saveAnswer('sin respuesta', this.state.count_time);
                        }
                    }
                    count = 0;
                    this.setState({count_time: 0});
                }
            }else{
                clearInterval(this.interval);
                count = 0;
                this.setState({count_time: 0});
                localStorage.setItem('error_count', 1);
                // this.timeLimitSimulation(simulate);
                toast.error('Ha existido un error en el contador se reiniciar la prueba para solventar este error',{
                    onClose: () => {
                        window.location.reload();
                    }
                })
            }
        }, 1000);
    }

    simulateAnswer = (answer) => () => {
        this.setState({blockQuestion: true});
        if(this.state.simulateQuestion[this.count_simulate].con_argumento == '1'){
            clearInterval(this.interval);
            this.state.simulateQuestion[this.count_simulate].respuestas.forEach((item, index) =>{
                item.showAnswer = false;
                if(item.respuesta == answer.respuesta){
                    item.showAnswer = true;
                }
            })
            this.state.simulateQuestion[this.count_simulate].mostrar_argumento = true;
            this.state.simulateQuestion[this.count_simulate].re_pregunta = answer.re_pregunta;
            this.setState({
                answerArgument: answer,
                simulateQuestion: this.state.simulateQuestion,
            });
        }else{
            this.ejecuteSimulateAnswer();
        }
    }

    ejecuteSimulateAnswer(){
        clearInterval(this.interval);
        this.setState({blockQuestion: false});
        let numSimulate = this.state.simulateQuestion.length - 1
        if(this.count_simulate < numSimulate){
            this.count_simulate++;
            this.setState({argumentText: '', answerArgument: ''});
            this.cambiar_pantalla(3);
            if(this.state.simulateQuestion[this.count_simulate].con_caso == '0'){
                this.timeLimitSimulation(true);
            }
        }else{
            this.cambiar_pantalla(4);
        }
    }

    showQuestion = (question, simulate) => () => {
        clearInterval(this.interval);
        question.mostrar_pregunta = true;
        this.timeLimitSimulation(simulate);
    }

    startEvaluation = () => () => {
        this.setState({blockStartEval: true});
        if(this.state.evaluation.estado_evaluacion == '1' || this.state.evaluation.estado_evaluacion == '2'){
            this.deleteQuestions();
        }else{
            this.continueStartEvaluation();
        }
        
    }

    async deleteQuestions(){
        const evaluationResultsDoc = firebase.firestore()
            .collection('resultados_evaluacion')
            .doc(this.props.params.evaluation_id);

        const batch = firebase.firestore().batch();
        
        let questionsQuerySnapshot = await evaluationResultsDoc.collection('resultados')
        .where('tipo_factor', 'in', ['0','1', '2', '3'])
        .get();

        questionsQuerySnapshot.forEach(documentSnapshot => {
            batch.delete(documentSnapshot.ref)
        })
        
        batch.commit().then((docRef) => {
            this.continueStartEvaluation();
        });
    }

    continueStartEvaluation(){
        clearInterval(this.interval);
        if(this.state.evaluation.con_camara == '1'){
            navigator.mediaDevices.getUserMedia({ video: true })
            .then((mediaStream) => this.successDevice(mediaStream))
            .catch((err) => this.catchDevice(err));
            // this.time_simulation = this.state.questions[this.count_questions].tiempo;
        }else{
            this.updateCamaraUser('0');
        }
    }

    successDevice(mediaStream){
        this.updateCamaraUser('1');
    }

    catchDevice(err){
        let mensaje = '';
        if(err.toString().indexOf(':')){
            let errSplit = err.toString().split(":");
            mensaje = errSplit[1];
        }else{
            mensaje = err;
        }
        if(window.confirm(mensaje+', Esta seguro de continuar?')){
            this.updateCamaraUser('0');
        }else{
            alert(
                "Instrucciones\n"+
                "1. Dirijase con el cursor a la parte superior del navegador.\n"+
                "2. A lado de la url encontrará un icono, en el cual debe dar click.\n"+
                "3. Se abrira una ventana emergente, donde podrá directamente activar la cámara.\n"+
                "4. Si no se muestra la opción de cámara puede dar click en configuraciones del sitio.\n"+
                "5. Finalmente en la pantalla que se abrira podrá habilitar los permisos de cámara.\n"
            );
        }
    }

    updateCamaraUser(status){
        let data = {
            evaluation_id: this.props.params.evaluation_id,
            status_camara: status
        };
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/update_status_camara', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((result) => {
            if(result.status == 1){
                let showCam = false;
                if(status == '1'){
                    showCam = true;
                }
                this.cambiar_pantalla(5);
                this.setState({showCam: showCam});
                this.timeLimitSimulation(false);
            }
        })
        .catch(console.error)
    }

    saveAnswer(answer, tiempo_porcentaje){
        clearInterval(this.interval);
        let respuesta = answer;
        let respuesta_id = this.state.empty_aswer.id;
        let calificacion = 0;
        if(answer != 'sin respuesta'){
            if(answer.respuesta != undefined){
                respuesta = answer.respuesta;
                respuesta_id = answer.id;
                calificacion = answer.calificacion;
                this.withoutActivity = 0;
            }
        }else{
            this.withoutActivity++;
        }
        if(this.withoutActivity <= 4){
            let tiempo = parseFloat(tiempo_porcentaje)*parseInt(this.time_simulation)/100;
            let name_image = '';
            if(this.state.questions[this.count_questions].con_camara == '1' && this.state.showCam){
                name_image = 'candidate/'+this.props.params.evaluation_id+'_'+this.state.questions[this.count_questions].id+'.jpeg';
                this.state.capturesAnswer.push({
                    question_id: this.state.questions[this.count_questions].id,
                    evaluation_id: this.props.params.evaluation_id
                });
                this.setState({capturesAnswer: this.state.capturesAnswer})
                this.captureImage(this.state.questions[this.count_questions].id);
            }
            let data_save = {
                evaluacion_candidatos_id: this.props.params.evaluation_id,
                preguntas_id: this.state.questions[this.count_questions].id,
                factores_id: this.state.questions[this.count_questions].factores_id,
                tipo_factor: this.state.questions[this.count_questions].tipo_factor,
                respuesta: respuesta,
                calificacion: calificacion,
                respuestas_id: respuesta_id,
                tiempo: tiempo,
                captura: name_image,
                respuesta_argumento: this.state.argumentText
            }
            this.setState({argumentText: '', answerArgument: ''});
            this.saveResult(data_save);
        }else{
            this.saveWithoutActivity('5');
        }
    }

    saveResult(data_save){
        const resultDocument = firebase.firestore()
            .collection('resultados_evaluacion')
            .doc(this.props.params.evaluation_id);

        resultDocument.collection('resultados')
        .doc(data_save.preguntas_id.toString())
        .set(data_save)
        .then((docRef) => {
            let answers_select = this.state.answers_select;
            answers_select[data_save.preguntas_id] = data_save;
            
            if((this.count_questions + 1) == this.state.questions.length){
                this.setState({showCam: false});
                this.cambiar_pantalla(6);
            }else{
                this.count_questions++;
                this.setState({blockQuestion: false});
                this.cambiar_pantalla(5);
                
                if(this.state.questions[this.count_questions].con_caso == '0'){
                    this.timeLimitSimulation(false);
                }
            }
            let percentage_questions = (this.count_questions * 100) / this.state.number_questions;
            this.setState({
                percentage_questions: percentage_questions,
                answers_select: answers_select
            });
        })
        .catch(err => {
            console.error(err);
            clearInterval(this.interval);
            this.setState({blockQuestion: false});
            this.cambiar_pantalla(5);
            this.time_simulation = this.state.question_time;
            this.timeLimitSimulation(false);
                      
            if((this.count_questions + 1) == this.state.questions.length){
                this.setState({showCam: false});
                this.cambiar_pantalla(6);
            }else{
              this.count_questions++;
            }
        })
    }

    saveAnswerForm = (answer, count_time) => () => {
        this.setState({blockQuestion: true});
        if(this.state.questions[this.count_questions].con_argumento == '1'){
            clearInterval(this.interval);
            if(answer.re_pregunta != ''){
                this.state.questions[this.count_questions].respuestas.forEach((item, index) =>{
                    item.showAnswer = false;
                    if(item.respuesta == answer.respuesta){
                        item.showAnswer = true;
                    }
                })
                this.state.questions[this.count_questions].mostrar_argumento = true;
                this.state.questions[this.count_questions].re_pregunta = answer.re_pregunta;
                this.setState({
                    answerArgument: answer,
                    questions: this.state.questions
                });
            }else{
                this.saveAnswer(answer, this.state.count_time);
            }
        }else{
            this.saveAnswer(answer, this.state.count_time);
        }
    }

    saveEvaluation = () => () => {
        this.setState({loading: true, loaderActive: 'loader-active', buttonDisabled: true});
        let results = this.generateCandidateResults();
        this.saveTotals(results);
    }

    generateCandidateResults(){
        let candidateResults = [];
        let main_factor_results = {};
        let totalResults = {totalTime: 0, totalPercentage: 0}
        let answerKeys = Object.keys(this.state.answers_select);
        answerKeys.forEach((answerKey) => {
            let answer = this.state.answers_select[answerKey];
            main_factor_results[answer.factores_id] = {puntaje: 0, tiempo: 0, total: 0};
        })

        answerKeys.forEach((answerKey) => {
            let answer = this.state.answers_select[answerKey];
            let qualification = parseFloat(answer.calificacion);
            main_factor_results[answer.factores_id].puntaje += qualification;
            main_factor_results[answer.factores_id].tipo_factor = answer.tipo_factor;
            main_factor_results[answer.factores_id].tiempo += parseFloat(answer.tiempo);
            main_factor_results[answer.factores_id].total++;
            totalResults.totalTime += answer.tiempo;
        })
        let factors = Object.keys(main_factor_results);
        factors.forEach((factor) => {
            let answer = main_factor_results[factor];
            if(answer.tipo_factor !== '2'){
                answer.porcentaje = Math.round((answer.puntaje * 100)/answer.total, 2);
                if(answer.tipo_factor === '0' || answer.tipo_factor === '3'){
                    answer.porcentaje = Math.round(100 - (answer.puntaje/answer.total) * 100, 2);
                }
                answer.candidatos_identificacion = this.state.evaluation.candidatos_identificacion;
                answer.factores_id = factor;
                answer.empresas_ruc = this.state.evaluation.empresas_ruc;
                candidateResults[answer.factores_id] = answer;
            }
        })
        let sumPercentage = 0;
        candidateResults.forEach(result => {
            sumPercentage += result.porcentaje;
        })

        totalResults.totalPercentage = sumPercentage/candidateResults.length;        

        return {candidateResults: candidateResults, totalResults: totalResults};
    }

    saveCandidateResults(candidateResults){
        const candidateResultsDoc = firebase.firestore()
            .collection('resultados_candidato')
            .doc(this.props.params.evaluation_id);

        let batch = firebase.firestore().batch();

        let factors = Object.keys(candidateResults);
        factors.forEach((factor) => {
            if(candidateResults[factor].tipo_factor !== '2'){
                let resultadosCollection = candidateResultsDoc.collection('resultados').doc(factor)
                batch.set(resultadosCollection, candidateResults[factor])
            }
        })
        batch.commit().then((docRef) => {
            // toast.info('Registros guardados con exito');
            if(this.state.evaluation.test_iq == '1'){
                let factorsIQSize = this.state.factorsIq.length;
                if(this.countFactors == factorsIQSize){
                    this.saveStatusEvaluationMysql()
                }else{
                    toast.info('Registros guardados con exito');
                    this.setState({loading: false, buttonDisabled: false});
                    this.cambiar_pantalla(7);
                }
            }else{
                this.saveStatusEvaluationMysql()
            }
        });
    }

    saveTotals(data){
        firebase.firestore()
        .collection('totals')
        .doc(this.props.params.evaluation_id)
        .set(data.totalResults)
        .then(() => {
            this.saveCandidateResults(data.candidateResults);
        });
    }

    saveStatusEvaluationMysql(){
        let data = {
            evaluation_id: this.props.params.evaluation_id,
            status: '3'
        }
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/evaluation_status', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                toast.info('Registros guardados con exito');
                this.setState({loading: false, buttonDisabled: false});
                this.cambiar_pantalla(7);
            }else{
                toast.error(data.text);
                this.setState({loading: false, buttonDisabled: false});
                this.props.navigate('/list_evaluations/'+this.state.evaluation.candidatos_identificacion);
            }
        })
        .catch(err =>{
            toast.error(err);
            this.setState({loading: false, buttonDisabled: false});
        })
    }
    
    saveWithoutActivity(status){
        let data = {
            evaluations_id: this.props.params.evaluation_id,
            status: status
        };
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/update_status_evaluation', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data.status === 1){
                this.logout();
            }else{
                toast.error(data.text);
                this.setState({loading: false, buttonDisabled: false});
            }
        })
        .catch(err =>{
            toast.error(err);
            this.setState({loading: false, buttonDisabled: false});
        })
    }

    salir() {
        this.props.navigate('/list_evaluations/'+this.state.evaluation.candidatos_identificacion);
    }

    closeEvaluation = () => () => {
        this.salir()
    }

    logout() {
        if(firebase.auth().currentUser.email === null){
            origin = '';
            firebase.auth().currentUser.delete().then(function () {
                console.log('delete successful?')
            }).catch(function (error) {
                console.error({error})
            })
        }
        firebase.auth().signOut()
        .then(() => {
            this.props.navigate('/');
        })
        .catch(error => {
            console.error(error);
            this.props.navigate('/');
        })
	}

    captureImage(question_id){
        let data = {
            name_image: question_id,
            evaluation_id: this.props.params.evaluation_id,
            image: this.webcam.getScreenshot()
        };
        fetch(const_values.SERVICE_CONNECT+'pruebas/save_image', {
            method: 'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((result) => {
            console.log('save image');
        })
        .catch(console.error)
    }

    async validFactoresIQ(){
        const candidateResultsDoc = firebase.firestore()
            .collection('resultados_candidato')
            .doc(this.props.params.evaluation_id);

        for (let index = 0; index < this.state.factorsIq.length; index++) {
            let factor = this.state.factorsIq[index];
            let resultCadidate = await candidateResultsDoc.collection('resultados').doc(factor.id).get();
            if(resultCadidate.data()){
                this.countFactors++;
            }
        }
        let factorsIQSize = this.state.factorsIq.length;
    }

    nextQuestionAnswer = (isSavedArgument,simulate) => () => {
        if(!isSavedArgument){
            this.setState({argumentText: ''});
        }
        if(simulate){
            this.ejecuteSimulateAnswer();
        }else{
            this.saveAnswer(this.state.answerArgument, this.state.count_time);
        }
    }
    
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({argumentText: value});
    }

    alertMouse(event) {
        // if (event.movementY < -10){
        //     alert('Esperaaaaa no te vayas');
        // }
    }

    render(){
        const { classes } = this.props;
        let candidate_name_complete = '';
        let candidate_name = '';
        let company_name = '';
        if(this.state.evaluation != ''){
            if(this.state.evaluation.nombre_candidato !== undefined){
                candidate_name_complete = (this.state.evaluation.nombre_candidato+' '+this.state.evaluation.apellido).toUpperCase();
                candidate_name = (this.state.evaluation.nombre_candidato).toUpperCase();
            }
            if(this.state.evaluation.razon_social !== undefined){
                company_name = (this.state.evaluation.razon_social).toUpperCase();
            }
        }
        if(!this.state.loadingInit){
            return (
                <Preloader/>
            )
        }else{
            const videoConstraints = {
                width: 640,
                height: 480,
                facingMode: "user"
            };
            let consentText = "su postulación en el proceso de selección";
            if(this.state.evaluation.tipos_evaluaciones_id == 2){
                consentText = "su calificación";
            }
            const marginTitle = 45;

            let heightBlock = window.screen.height - 68 - 64 - 142;
            if(window.screen.height <= 480){
                heightBlock = window.screen.height - 68 - 80;
            }
            return(
                <div onMouseMove={this.alertMouse}>
                    {this.state.evaluation.con_camara == '1' && this.state.showCam &&
                        <div className="div-cam">
                            <Webcam
                                audio={false}
                                height={230}
                                ref={node => this.webcam = node}
                                screenshotFormat="image/jpeg"
                                width={230}
                                videoConstraints={videoConstraints}
                            />
                        </div>
                    }
                    {this.state.visible[0] && (
                        <div className="evaluation-div">
                        <Grid container style={{minHeight: heightBlock}}>
                            <SequenceEvaluation active={0}/>
                            <Grid item md={9} xs={12} className="separate-grid">
                                <div className="card1 border-radius elevation">
                                    <div>
                                        <h3 style={{marginBottom: marginTitle}}><strong>Autorización de polígrafo</strong></h3>
                                        <div className='section-paragraph'>
                                            <p>
                                                Entiendo que, las respuestas proporcionadas por mi persona serán de total 
                                                veracidad, no alteraré las respuestas para dar una imagen falsa de mi 
                                                persona y, en cualquier momento, en un futuro, podrán ser validadas 
                                                mediante una prueba de polígrafo en caso de que la empresa lo requiera.
                                            </p>
                                            <p>
                                                Por lo que, Yo {candidate_name_complete}, de libre voluntad y sin
                                                presión alguna, autorizo la aplicación de una prueba de polígrafo, 
                                                previamente haber entendido lo siguiente:
                                            </p>
                                            
                                            <p>
                                                La prueba de polígrafo solamente se realizará si {company_name} lo 
                                                requiere, con el objetivo de validar la información proporcionada en esta 
                                                evaluación en línea.
                                            </p>
                                            <p>
                                                Los resultados de la prueba de polígrafo serán confidenciales y solo serán 
                                                entregados a {company_name}.
                                            </p>
                                            <p>Una vez leído el documento,</p>
                                        </div>
                                        <div>
                                            <CheckEvaluation 
                                                status={!this.state.isAuthorizePolygraph}
                                                action={(element) => this.setState({isAuthorizePolygraph: !this.state.isAuthorizePolygraph})}
                                                text={`Autorizo para la aplicación de la prueba de polígrafo en caso de que ${company_name} así lo requiera.`}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='button-next'>
                                            <Button color="primary" variant="contained" disabled={this.state.isAuthorizePolygraph} onClick={this.authorizePolygraph('SI')}>
                                            Continuar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        </div>
                    )}
                    {this.state.visible[1] && (
                        <div className="evaluation-div">
                        <Grid container style={{minHeight: heightBlock}}>
                            <SequenceEvaluation active={1}/>
                            <Grid item md={9} xs={12} className="separate-grid">
                                <div className="card1 border-radius elevation">
                                    <div>
                                        <h3 style={{marginBottom: marginTitle}}><strong>Confidencialidad de información</strong></h3>
                                        <div className='section-paragraph'>
                                            <p> 
                                                Para su conocimiento, todos los resultados arrojados por esta evaluación en línea serán entregados 
                                                exclusivamente a <strong>{company_name}</strong>, por lo que, <strong>{company_name}</strong> se 
                                                compromete a utilizar la información con total ética y profesionalismo, guardando total confidencialidad 
                                                sobre la información proporcionada.
                                            </p>
                                        </div>
                                        <div>
                                            <CheckEvaluation 
                                                status={!this.state.isConfidentiality}
                                                action={(element) => this.setState({isConfidentiality: !this.state.isConfidentiality})}
                                                text={`Entiendo plenamente las políticas de confidencialidad de información.`}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='button-next'>
                                            <Button color="primary" variant="contained" disabled={this.state.isConfidentiality} onClick={this.confidentialityAgreement()}>
                                            Continuar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        </div>
                    )}
                    {this.state.visible[2] && (
                        <div className="evaluation-div">
                        <Grid container style={{minHeight: heightBlock}}>
                            <SequenceEvaluation active={2}/>
                            <Grid item md={9} xs={12} className="separate-grid">
                                <div className="card1 border-radius elevation">
                                    <div>
                                        <h3 style={{marginBottom: marginTitle}}><strong>Instrucciones generales</strong></h3>
                                        <div className='section-paragraph'>
                                            <p>Te recomendamos atentamente leer las siguientes recomendaciones antes de iniciar la evaluación.</p>
                                            <ol className='list-intructions'>
                                                <li>Las preguntas aparecerán en la pantalla de una en una.</li>
                                                <li>Cada pregunta tendrá varias respuestas, deberá seleccionar la que más se ajuste a su caso.</li>
                                                <li>No se puede corregir una respuesta seleccionada, estar seguro de seleccionar la que usted considere apropiada.</li>
                                                <li>El sistema avanzará automáticamente cuando usted seleccione su respuesta.</li>
                                                <li>Existe tiempo limitado para responder a cada pregunta, procure responder lo más pronto posible.</li>
                                            </ol>
                                            <p>Recuerda que no existen respuestas buenas o malas.</p>
                                        </div>
                                        <div>
                                            <CheckEvaluation 
                                                status={!this.state.isInstructions}
                                                action={(element) => this.setState({isInstructions: !this.state.isInstructions})}
                                                text={`Entiendo plenamente las instrucciones de la evaluación.`}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                    <div className='button-next'>
                                        <Button color="primary" variant="contained" disabled={this.state.isInstructions} onClick={this.simulateEvaluation()}>
                                        Continuar
                                        </Button>
                                    </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        </div>
                    )}
                    {this.state.visible[3] && (
                        <div className="evaluation-div">
                        <Question
                            questions={this.state.simulateQuestion} 
                            count_questions={this.count_simulate} 
                            count_time={this.state.count_time} 
                            blockQuestion={this.state.blockQuestion} 
                            simulate={true} 
                            argumentText={this.state.argumentText}
                            showQuestion={this.showQuestion} 
                            saveAnswer={this.simulateAnswer} 
                            nextQuestionAnswer={this.nextQuestionAnswer}
                            handleInputChange={this.handleInputChange}
                            height={heightBlock}
                        />
                        </div>
                    )}
                    {this.state.visible[4] && (
                        <div className="evaluation-div">
                        <Grid container style={{minHeight: heightBlock}}>
                            <SequenceEvaluation active={3}/>
                            <Grid item md={9} xs={12} className="separate-grid">
                                <div className="card1 border-radius elevation">
                                    <div>
                                        <h3 style={{marginBottom: marginTitle}}>
                                            Estás a un paso de comenzar tu evaluación.
                                        </h3>
                                        <div className='section-paragraph'>
                                            <p> 
                                                Recuerda seguir las instrucciones del test y responder con la verdad en todo momento, 
                                                de no hacerlo, podrías afectar los resultados de tu evaluación y, a su vez, tu proceso 
                                                de evaluación.
                                            </p>
                                            <p> 
                                                Al presionar el botón continuar, la evaluación te solicitará encender tu cámara web, 
                                                asegúrate de tener una cámara conectada al computador antes de comenzar con la prueba.
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                    <div className='button-next'>
                                        <Button color="primary" variant="contained" onClick={this.startEvaluation()} disabled={this.state.blockStartEval}>
                                            Continuar
                                        </Button>
                                    </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        </div>
                    )}
                    {this.state.visible[5] && (
                        <div className="evaluation-div">
                            <Question
                                questions={this.state.questions} 
                                count_questions={this.count_questions} 
                                count_time={this.state.count_time} 
                                blockQuestion={this.state.blockQuestion} 
                                simulate={false} 
                                argumentText={this.state.argumentText}
                                showQuestion={this.showQuestion} 
                                saveAnswer={this.saveAnswerForm} 
                                nextQuestionAnswer={this.nextQuestionAnswer}
                                handleInputChange={this.handleInputChange}
                                height={heightBlock}
                            />
                            <div style={{marginBottom: 20}}>
                                <QuestionLinearProgress variant="determinate" value={this.state.percentage_questions}/>
                                {/* <LinearProgress variant="determinate" 
                                    classes={{
                                        colorPrimary: classes.colorPrimary, 
                                        barColorPrimary: classes.barColorPrimary
                                    }} 
                                    value={this.state.percentage_questions} 
                                /> */}
                            </div>
                        </div>
                    )}
                    {this.state.visible[6] && (
                        <Grid container style={{minHeight: heightBlock}}>
                            <Grid item md={7} xs={12}>
                                <div className='div-final'>
                                    <div>
                                        <h3 style={{marginBottom: marginTitle}}>
                                            Estás a un paso de finalizar la evaluación.
                                        </h3>
                                        <p> 
                                            Para guardar tus respuestas correctamente, por favor,
                                            presiona el siguiente botón.
                                        </p>
                                    </div>
                                    <div className={this.state.loaderActive}>
                                        <PropagateLoader color='#0000FF' loading={this.state.loading} css={override} size={15} />
                                    </div>
                                    <div>
                                        <Button color="primary" variant="contained"  onClick={this.saveEvaluation()}  disabled={this.state.buttonDisabled}>
                                            Guardar respuestas
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={5} xs={12} className="separate-grid div-empty"></Grid>
                        </Grid>
                    )}
                    {this.state.visible[7] && (
                        <Grid container style={{minHeight: heightBlock}}>
                            <Grid item md={7} xs={12}>
                                <div className='div-final'>
                                    <p>
                                        Gracias por su evaluación, la empresa {company_name}, se pondra en contacto con usted. 
                                    </p>
                                    <div className={this.state.loaderActive}>
                                        <PropagateLoader color='#0000FF' loading={this.state.loading} css={override} size={15} />
                                    </div>
                                    <div>
                                        <Button color="primary" variant="contained" onClick={this.closeEvaluation()}>
                                            Salir
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={5} xs={12} className="separate-grid div-empty"></Grid>
                        </Grid>
                    )}
                    <div className='div-logo-company'>
                        <Image src={const_values.IMAGE_COMPANY+this.state.evaluation.imagen} 
                            style={{width: 190}}
                        />
                    </div>
                </div>
            )
        }
    }
}

// Evaluation.propTypes = {
//     classes: PropTypes.object.isRequired
// }

export default Evaluation;
