import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WrapperComponent from '../utils/WrapperComponent';
import Logout from '../screens/auth/Logout';
import UpdateMigration from '../screens/migration/UpdateMigration';
import ResultsEvaluations from '../screens/migration/ResultsEvaluations';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default class Admin extends Component {

    dimensionWindow() {
        let tam = { width: 0, height: 0 };
        if (typeof window.innerWidth !== 'undefined') {
            tam = { width: window.innerWidth, height: window.innerHeight };
        }
        else if (typeof document.documentElement !== 'undefined'
            && typeof document.documentElement.clientWidth !==
            'undefined' && document.documentElement.clientWidth !== 0) {
            tam = {
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight
            };
        }
        else {
            tam = {
                width: document.getElementsByTagName('body')[0].clientWidth,
                height: document.getElementsByTagName('body')[0].clientHeight
            };
        }
        return tam;
    }

    render() {
        const dimensions = this.dimensionWindow();
        return (
            <BrowserRouter>
                <Container fluid style={{ height: dimensions.height }}>
                    <Header origin='migration' />
                    <Row className="body-block">
                        <Col>
                            <Routes>
                                <Route exact path="/" element={<WrapperComponent item={ResultsEvaluations}/>} />
                                <Route exact path="/migration" element={<WrapperComponent item={UpdateMigration}/>} />
                                {/* <Route exact path="/resultados" component={ResultsEvaluations} /> */}
                                <Route exact path="/logout/" element={<WrapperComponent item={Logout}/>} />
                            </Routes>
                        </Col>
                    </Row>
                    <Footer styleFooter="footer-block" />
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Container>
            </BrowserRouter>
        )
    }
}
