import React, {Component} from 'react';
import '../App.css';
import { Grid } from '@mui/material';

export default class SequenceEvaluation extends Component{

    render(){
        const { active } = this.props;
        let listCss = [];
        for (let index = 0; index < 4; index++) {
            listCss[index] = 'text-color-inactive';
            if(index == active){
                listCss[index] = 'text-color-active';
            }
        }
        if(window.screen.width > 480){
            return(
                <Grid item md={3} xs={12}>
                    <div className='items-sequence'>
                        <ul className='list-sequence'>
                            <li className={listCss[0]}>Autorización de polígrafo</li>
                            <li className={listCss[1]}>Confidencialidad de información</li>
                            <li className={listCss[2]}>Instrucciones generales</li>
                            <li className={listCss[3]}>Comenzar evaluación</li>
                        </ul>
                    </div>
                </Grid>
            )
        }else{
            return (
                <Grid item md={3} xs={12}>
                    <div style={{marginTop: 60}}></div>
                </Grid>
            )
        }
    }
}