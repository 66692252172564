// export default {
//   apiKey: "AIzaSyBSEizEETxaL0FX-AAPf3LJR9taGawQPOc",
//   authDomain: "integritas-a3fc3.firebaseapp.com",
//   projectId: "integritas-a3fc3",
//   storageBucket: "integritas-a3fc3.appspot.com",
//   messagingSenderId: "858378527689",
//   appId: "1:858378527689:web:f59b152c41a31cc0d77e18",
//   measurementId: "G-GPRYFX7SD0"
// };

// export default {
//   apiKey: "AIzaSyAX4pZU-M5PUFvV37o0pTKlhhSDsJBvkYw",
//   authDomain: "prueba-integritas.firebaseapp.com",
//   projectId: "prueba-integritas",
//   storageBucket: "prueba-integritas.appspot.com",
//   messagingSenderId: "1074551710050",
//   appId: "1:1074551710050:web:e209d4dd75450ea2410c3b",
//   measurementId: "G-C9KV2GS07P"
// };
export default {
  apiKey: "AIzaSyA5mvir0MQ1rZGdCFJQ9frZGLb3GScBaBk",
  authDomain: "encuestasintegritas.firebaseapp.com",
  projectId: "encuestasintegritas",
  storageBucket: "encuestasintegritas.appspot.com",
  messagingSenderId: "330107520650",
  appId: "1:330107520650:web:42bb046e80d7d3096b0f73",
  measurementId: "G-QHM46Q02CT"
};
