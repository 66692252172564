export default {
    COLOR_INVESTMENTS: '#FFBF00',
    COLOR_ENTREPRENEUR: '#D8D8D8',
 
    // SERVICE_CONNECT: 'https://integritas.ec/es/prueba/server/api/public/',
    // IMAGE_CONNECT: 'https://integritas.ec/es/prueba/server/api/',
    
    // SERVICE_CONNECT: 'http://localhost/server_integritas_new/server/api/public/',
    // IMAGE_CONNECT: 'http://localhost/server_integritas_new/server/api/',
    // IMAGE_COMPANY: 'https://empresas.integritas.ec/assets/companies/',

    // SERVICE_CONNECT: 'http://192.168.1.102/server_integritas_new/server/api/public/',
    // IMAGE_CONNECT: 'http://192.168.1.102/server_integritas_new/server/api/',
    // IMAGE_COMPANY: 'https://empresas.integritas.ec/assets/companies/',
    
    SERVICE_CONNECT: 'https://empresas.integritas.ec/server/api/public/',
    IMAGE_CONNECT: 'https://empresas.integritas.ec/server/api/',
    IMAGE_COMPANY: 'https://empresas.integritas.ec/assets/companies/',
}