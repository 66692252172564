import React, {Component} from 'react';
import { Row, Col } from 'react-bootstrap';
import '../App.css';

export default class Footer extends Component{

    render(){
        const { styleFooter } = this.props;
        return(
            <Row className={styleFooter}>
                <Col>
                    <div>
                        Integritas es un producto de Grupo Toledo. Todos los derechos reservados.
                    </div>
                </Col>
            </Row>
        )
    }
}