import React, {Component} from 'react';
import { Table, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import '../../App.css';

import const_values from '../../utils/ConstValues';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default class ResultsEvaluations extends Component{
    
    constructor(){
        super();
        this.state={
            with_results: '',
            evaluations: []
        }
    }

    componentDidMount(){
        let parameter = this.props.params;
        this.getEvaluations();
    }

    getEvaluations(){
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/evaluaciones_sin_resultados')
        .then(res => res.json())
        .then((data) => {
            if(data.status == 1){
                this.setState({
                    with_results: '',
                    evaluations: data.text
                })
            }else{
                this.setState({with_results: 'No existen evaluacion sin registros'})
            }
        })
        .catch(console.error)
    }

    insertResults = (evaluation) => () => {
        let evaluation_id = evaluation.id;
        const resultDocument = firebase.firestore()
            .collection('resultados_evaluacion')
            .doc(evaluation_id);

        resultDocument.collection('resultados')
        .get()
        .then((snapshot) => {
            let data = [];
            snapshot.forEach(item => {
                data.push(item.data());
            })
            data.forEach(element => {
                delete element.factores_id;
                delete element.tipo_factor;
            })
            this.saveDataMysql(evaluation_id, data);
        });
    }

    saveDataMysql(evaluation_id, evaluationResults){
        let data = {
            evaluation_canditate_id: evaluation_id,
            evaluationResults: evaluationResults
        }
        fetch(const_values.SERVICE_CONNECT+'evaluaciones/evaluation_completed', {
            method: 'POST',
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data.status == 1){
                toast.info(data.text);
            }else{
                toast.error(data.text);
            }
            this.getEvaluations();
        })
        .catch(err =>{
            toast.error(err);
            this.setState({loading: false, buttonDisabled: false});
        })
    }

    logout() {
        if(firebase.auth().currentUser.email === null){
            origin = '';
            firebase.auth().currentUser.delete().then(function () {
                console.log('delete successful?')
            }).catch(function (error) {
                console.error({error})
            })
        }
        firebase.auth().signOut()
        .then(() => {
            this.props.navigate('/');
        })
        .catch(error => {
            console.error(error);
            this.props.navigate('/');
        })
	}
    
    salir = () => () => {
        this.logout();
        // window.open('https://www.integritas.ec/', '_self');
    }

    render(){
        return(
            <div>
                <div style={{textAlign: 'center', marginTop: 40, fontWeight: 'bold'}}>
                    EVALUACIONES SIN RESULTADOS
                </div>
                {this.state.with_results == ''?
                    (
                        <div>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <td>Empresa</td>
                                        <td>Evaluado</td>
                                        <td>Fecha</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.evaluations.map((item, index) => (
                                        <tr key={'E'+index}>
                                            <td>{item.razon_social}</td>
                                            <td>{item.evaluado}</td>
                                            <td>{item.fecha_evaluacion}</td>
                                            <td>
                                                <Button variant="primary" size="sm" onClick={this.insertResults(item)}>
                                                    Recuperar resultados
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )
                    :
                    (
                        <div>this.state.with_results</div>
                    )
                }
                <div>
                    <Button variant="primary" size="sm" onClick={this.salir()}>
                        Salir
                    </Button>
                </div>
            </div>
        )
    }
}
