import React, {Component} from 'react';
import '../App.css';
import { Grid, Button } from '@mui/material';
import ProgressQuest from './ProgressQuest';

export default class Question extends Component{

    render(){
        const { 
            questions, 
            count_questions, 
            count_time, 
            blockQuestion, 
            simulate, 
            argumentText,
            showQuestion, 
            saveAnswer, 
            nextQuestionAnswer,
            handleInputChange,
            height
        } = this.props;
        return(
            <Grid container style={{minHeight: height}}>
                <Grid item md={7} xs={12}>
                    <div className='div-question'>
                        {questions[count_questions].con_caso == '1' && (
                            <div>
                                <div style={{color: '#A4A4A4'}}>
                                    <p>{questions[count_questions].caso}</p>
                                </div>
                                {!questions[count_questions].mostrar_pregunta && (
                                    <div style={{marginTop: 20}}>
                                        <Button color="primary" variant="contained" onClick={showQuestion(questions[count_questions], simulate)}>
                                            Mostrar pregunta
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                        {questions[count_questions].mostrar_pregunta && (
                            <div>
                                {questions[count_questions].pregunta}
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid item md={5} xs={12} className="separate-grid">
                    {questions[count_questions].mostrar_pregunta && (
                        <div className="div-aswers">
                            <div style={{marginBottom: 30}}>
                                <ProgressQuest value={count_time}/>
                            </div>
                            <div className="result">
                                {questions[count_questions].respuestas.map((item, index) => 
                                    item.showAnswer && (
                                        <div key={'Q'+index}>
                                            <Button 
                                                onClick={saveAnswer(item, count_time)} 
                                                className='button-answer'
                                                disabled={blockQuestion}
                                            >
                                                {item.respuesta}
                                            </Button>
                                        </div>
                                    )
                                )}
                            </div>
                            <div>{questions[count_questions].mostrar_argumento}</div>
                            {questions[count_questions].mostrar_argumento && (
                                <div>
                                    <div style={{fontSize: 18, marginBottom: 20, fontWeight: 400}}>
                                        {questions[count_questions].re_pregunta}
                                    </div>
                                    <textarea 
                                        value={argumentText}
                                        onChange={handleInputChange}
                                        maxLength={200}
                                        rows={4}
                                        className='argument-answer'
                                    />
                                    <div className='buttons-argument'>
                                        <Button className='button-argument1' onClick={nextQuestionAnswer(false, simulate)}>
                                            Continuar sin argumentar
                                        </Button>
                                        <Button color="primary" variant="contained" onClick={nextQuestionAnswer(true, simulate)}>
                                            Continuar
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Grid>
            </Grid>
        )
    }
}